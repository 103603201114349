import "styles/pages/text-page.scss"

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const StorvPage = ({ data }) => {
  const title = "Størv"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} color="#fff" />
      <div
        className="text-page-hero"
        style={{
          backgroundImage: `url(${
            require("assets/images/storv-bg.jpg").default
          })`,
        }}
      ></div>

      <section className="text-page-main">
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-xl-8">
              <h1 className="text-page-main__title">Størv</h1>
              <div className="row">
                <div className="col-md-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.wpPage.acfMbm.contentLeft,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMjI0" }) {
      id
      title
      acfMbm {
        contentLeft
        contentRight
      }
    }
  }
`

export default StorvPage
